

































import BaseCard from "@/components/Base/BaseCard.vue";
import BaseForm from "@/components/Base/BaseForm.vue";
import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseForm,
    },
})
export default class SharedSettingChangePassword extends Vue {
    @Prop() loading!: boolean;
    @Prop() forms!: any;

    public isFormValid: boolean = false;
    public isNewPasswordMatched: boolean = false;

    public data: any = {};

    @Emit("profile:change-password")
    changePassword() {
        return this.data;
    }

    @Watch("data", { deep: true })
    async checkValidation(data: any) {
        const newPassword = data.new_password;
        const confirmNewPassword = data.confirm_new_password;

        this.isNewPasswordMatched = newPassword === confirmNewPassword;
    }
}
